
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import session from "@/vuex/session";
import CommonProgress from "@/components/common/Progress.vue";

@Component({
  components: {
    CommonProgress,
  }
})
export default class Login extends Mixins(utilMixins) {
  
}


